import { kebab } from 'case';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

const sizeToClass = {
  sm: 'is-normal',
  md: 'is-medium',
  lg: 'is-large',
};

const TagList = ({ size, tags }) => (
  <div className="tags">
    {tags.map(tag => (
      <Link key={tag} className={`tag ${sizeToClass[size]}`} to={`/tags/${kebab(tag)}`}>
        {tag}
      </Link>
    ))}
  </div>
);

TagList.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  tags: PropTypes.arrayOf(PropTypes.string),
};

TagList.defaultProps = {
  size: 'sm',
  tags: [],
};

export default TagList;
