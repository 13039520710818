import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Layout from '../../components/Layout';
import ProjectList from '../../components/ProjectList';
import { filenameToSlug, mdxToEntities } from '../../helpers';

const ProjectsPage = ({ data }) => {
  const projects = mdxToEntities(data.projects).map(({ icon, ...otherFields }) => ({
    ...otherFields,
  }));

  return (
    <Layout title="Projects" slug={filenameToSlug(__filename)}>
      <h1>Projects</h1>
      <ProjectList projects={projects} />
    </Layout>
  );
};

ProjectsPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default ProjectsPage;

export const projectsPageQuery = graphql`
  query ProjectsPage {
    projects: allMdx(
      filter: { frontmatter: { template: { eq: "project" } } }
      sort: { fields: [frontmatter___order] }
    ) {
      ...Projects
    }
  }
`;
