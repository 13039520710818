import PropTypes from 'prop-types';
import React from 'react';

import propTypes from '../propTypes';
import Icon from './Icon';
import Image from './Image';
import TagList from './TagList';

const DateLabel = ({ children }) => {
  if (!children) {
    return null;
  }

  const date = new Date(children).toLocaleString('default', {
    day: '2-digit',
    month: 'short',
  });
  return (
    <time className="date" dataTime={date}>
      <span>📅</span>
      <span>{date} </span>
    </time>
  );
};

DateLabel.propTypes = {
  children: PropTypes.node,
};

DateLabel.defaultProps = {
  children: null,
};

const ImageLink = ({ image, target }) => {
  const Wrapper = target ? (
    ({ children }) => (
      <a href={target} target="_blank" rel="noreferrer noopener">
        {children}
      </a>
    )
  ) : (
    <></>
  );

  return (
    <div className="page-link-image">
      <Wrapper>
        <Image source={image} linkToSource={!target} />
      </Wrapper>
    </div>
  );
};

ImageLink.propTypes = {
  image: propTypes.image.isRequired,
  target: PropTypes.string,
};

ImageLink.defaultProps = {
  target: '',
};

const PageLink = ({ className, slug, title, icon, description, image, imageTarget, tags }) => (
  <div className={`${className} page-link`}>
    <div className="page-link-title">
      {icon && <Icon className="is-hidden-mobile" icon={icon} size="sm" />}
      <a href={slug}>{title}</a>
    </div>
    <p className="page-link-description">{description}</p>
    {image && <ImageLink image={image} target={imageTarget} />}
    <div>
      <TagList tags={tags} />
    </div>
  </div>
);

PageLink.propTypes = {
  className: PropTypes.string,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: propTypes.image,
  description: PropTypes.string,
  image: propTypes.image,
  imageTarget: PropTypes.string,
  tags: propTypes.tags,
};

PageLink.defaultProps = {
  className: '',
  icon: null,
  description: '',
  image: '',
  imageTarget: '',
  tags: [],
};

export default PageLink;
